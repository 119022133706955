import { Account, SafeModeAccount, TxResponse } from '@/wallet-utils'
import { z } from 'zod'

export interface MetaDataHandlerParameters<T> {
  response: TxResponse
  message: T
  selectedAccount: Account
  strideAccount: Account
  dexAccount: Account | undefined
}

export interface MetaDataFactoryParameters<T> {
  response: TxResponse
  message: T
  strideAccount: Account
  selectedAccount: Account
  dexAccount: Account | undefined
}

export interface RedemptionMetaDataFactoryParameters {
  unbonding: z.infer<typeof unbondingSchema>
  selectedAccount: Account | SafeModeAccount
}

export interface TiaRedemptionMetaDataFactoryParameters {
  payload: z.infer<typeof redemptionRecordPayloadSchema>
  selectedAccount: Account | SafeModeAccount
  strideAccount: Account | SafeModeAccount
}

export interface DymRedemptionMetaDataFactoryParameters {
  payload: z.infer<typeof redemptionRecordPayloadSchema>
  selectedAccount: Account | SafeModeAccount
  strideAccount: Account | SafeModeAccount
}

// For everything non-TIA
export const unbondingSchema = z.object({
  address: z.string(),
  receiver: z.string(),
  unbonding_estimated_time: z.string(),
  amount: z.string(),
  denom: z.string(),
  claim_is_pending: z.boolean(),
  epoch_number: z.string()
})

export const unbondingsResponseSchema = z.object({
  address_unbondings: z.array(unbondingSchema)
})

// for TIA
export const redemptionRecordPayloadSchema = z.object({
  redemption_record: z.object({
    unbonding_record_id: z.string(),
    redeemer: z.string(),
    st_token_amount: z.string(),
    native_amount: z.string()
  }),

  unbonding_completion_time_seconds: z.string()
})

export const redemptionRecordResponseSchema = z.object({
  redemption_record_responses: z.array(redemptionRecordPayloadSchema)
})
