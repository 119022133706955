import React, { useContext, useState } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import { IBCTransferStatus } from '@/wallet-utils'
import {
  useSignWithdrawStTokenToDexMutation,
  useBroadcastWithdrawStTokenToDexMutation,
  useTraceIbcTransfer,
  BroadcastStakeToWithdrawStTokenToDexMutationReturnType,
  MutationParameters
} from './mutations'

interface WithdrawStTokenToDexContextType {
  resetWithdrawStTokenToDexState: () => void

  signStakeToWithdrawStTokenToDex: () => Promise<TxRaw>
  isSigningStakeToWithdrawStTokenToDex: boolean
  signStakeToWithdrawStTokenToDexError: unknown

  broadcastStakeToWithdrawStTokenToDex: () => Promise<BroadcastStakeToWithdrawStTokenToDexMutationReturnType>
  isBroadcastingStakeToWithdrawStTokenToDex: boolean
  broadcastStakeToWithdrawStTokenToDexError: unknown

  ibcStatus: { status: IBCTransferStatus } | undefined
  traceIbcStatus: () => Promise<{ status: IBCTransferStatus }>
  isIbcStatusLoading: boolean
  ibcStatusError: unknown
}

const WithdrawStTokenToDexContext = React.createContext<WithdrawStTokenToDexContextType | null>(null)

const WithdrawStTokenToDexProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [withdrawStTokenToDexRaw, setWithdrawStTokenToDexRaw] = useState<TxRaw | null>(null)

  const [withdrawStTokenToDexTransaction, setWithdrawStTokenToDexTransaction] = useState<DeliverTxResponse | null>(null)

  const parameters: MutationParameters = {
    withdrawStTokenToDexRaw,
    setWithdrawStTokenToDexRaw,
    withdrawStTokenToDexTransaction,
    setWithdrawStTokenToDexTransaction
  }

  const {
    mutateAsync: signStakeToWithdrawStTokenToDex,
    isPending: isSigningStakeToWithdrawStTokenToDex,
    error: signStakeToWithdrawStTokenToDexError,
    reset: resetSignStakeToWithdrawStTokenToDex
  } = useSignWithdrawStTokenToDexMutation(parameters)

  const {
    mutateAsync: broadcastStakeToWithdrawStTokenToDex,
    isPending: isBroadcastingStakeToWithdrawStTokenToDex,
    error: broadcastStakeToWithdrawStTokenToDexError,
    reset: resetBroadcastStakeToWithdrawStTokenToDex
  } = useBroadcastWithdrawStTokenToDexMutation(parameters)

  const {
    data: ibcStatus,
    mutateAsync: traceIbcStatus,
    isPending: isIbcStatusLoading,
    error: ibcStatusError,
    reset: resetIbcStatus
  } = useTraceIbcTransfer(parameters)

  const resetWithdrawStTokenToDexState = () => {
    setWithdrawStTokenToDexRaw(null)
    setWithdrawStTokenToDexTransaction(null)
    resetSignStakeToWithdrawStTokenToDex()
    resetBroadcastStakeToWithdrawStTokenToDex()
    resetIbcStatus()
  }

  return (
    <WithdrawStTokenToDexContext.Provider
      value={{
        resetWithdrawStTokenToDexState,

        signStakeToWithdrawStTokenToDex,
        isSigningStakeToWithdrawStTokenToDex,
        signStakeToWithdrawStTokenToDexError,

        broadcastStakeToWithdrawStTokenToDex,
        isBroadcastingStakeToWithdrawStTokenToDex,
        broadcastStakeToWithdrawStTokenToDexError,

        ibcStatus,
        traceIbcStatus,
        isIbcStatusLoading,
        ibcStatusError
      }}>
      {children}
    </WithdrawStTokenToDexContext.Provider>
  )
}

const useWithdrawStTokenToDex = () => {
  const context = useContext(WithdrawStTokenToDexContext)

  if (context == null) {
    throw fatal('You forgot to mount StakeLiquidityProvider.')
  }

  return context
}

export { WithdrawStTokenToDexProvider, useWithdrawStTokenToDex }
