import React, { useMemo } from 'react'
import { Button } from '@mantine/core'
import { StepperModalContent, useStepperContext } from '@/components'
import { useWithdrawLsmFromTokenizeModal } from './WithdrawLsmFromTokenizeModalProvider'
import { LsmValidatorName } from '../shared'
import { getValidatorAddressFromLsmTokenizedShare } from '../utils'
import { useStake } from '../StakeProvider'
import { useSelectedCoin } from '@/contexts/wallet'
import { formatMicroDenom } from '@/wallet-utils'
import { CHAIN_INFO_LIST } from '@/config'

const WithdrawLsmFromTokenizeModalStepOne: React.FC = () => {
  const { signRedeemTokenForShares, isSigningRedeemTokenForShares, signRedeemTokenForSharesError } =
    useWithdrawLsmFromTokenizeModal()

  const { start, nextStep, forceClose } = useStepperContext()

  const denom = useSelectedCoin()

  const { withdrawLsm } = useStake()

  const validatorAddress = useMemo(() => {
    return getValidatorAddressFromLsmTokenizedShare(withdrawLsm.txHistoryTokenizedDenom)
  }, [withdrawLsm])

  const handleStart = async () => {
    start()
    await signRedeemTokenForShares()
    nextStep()
  }

  if (signRedeemTokenForSharesError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description="This redemption could not be completed. Your tokenized balances have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStart}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  const chainInfo = CHAIN_INFO_LIST[denom]

  const formattedAmount = `${formatMicroDenom(
    withdrawLsm.amount,
    chainInfo.stakeCurrency.coinMinimalDenom,
    3
  )} ${denom}`

  if (isSigningRedeemTokenForShares) {
    return (
      <StepperModalContent
        title={`Approve the transaction in your wallet to continue`}
        description={
          <>
            This will restore your previous staked position with the amount of {formattedAmount} to{' '}
            <LsmValidatorName address={validatorAddress} />.
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={`You’re about to revert ${formattedAmount} back to its original state.`}
      description={`This should take about a minute and will require 1 wallet approval.`}
      actions={
        <>
          <Button variant="outline" color="dark" onClick={forceClose}>
            Cancel
          </Button>

          <Button onClick={handleStart}>Revert</Button>
        </>
      }
    />
  )
}

export { WithdrawLsmFromTokenizeModalStepOne }
