import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { notify } from '@/contexts/notifications'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import { assertIsDeliverTxSuccess } from '@/wallet-utils'
import { MutationParameters } from './types'
import { useChainWallet } from '@/contexts/wallet'

export type BroadcastSendTokenMutationMode = 'deposit' | 'withdraw'

export interface BroadcastStakeIbcxMutationReturnType {
  transaction: DeliverTxResponse
}

const useBroadcastStakeIbcxMutation = ({ stakeIbcxRaw }: MutationParameters) => {
  const { data: osmosisAccount } = useChainWallet('OSMO')

  const client = useQueryClient()

  const handleMutation = async (): Promise<BroadcastStakeIbcxMutationReturnType> => {
    if (!osmosisAccount || !osmosisAccount.cosmwasm) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!stakeIbcxRaw) {
      throw fatal('Unable to broadcast transaction without a signed transaction raw.')
    }

    const bytes = TxRaw.encode(stakeIbcxRaw).finish()

    const transaction = await osmosisAccount.cosmwasm.broadcastTx(bytes)

    assertIsDeliverTxSuccess(transaction)

    return { transaction }
  }

  const handleSuccess = async ({ transaction }: BroadcastStakeIbcxMutationReturnType) => {
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })

    await client.invalidateQueries({
      queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: transaction.transactionHash })
    })

    if (!osmosisAccount) {
      return
    }

    try {
      await client.refetchQueries('chainAccount')
    } catch (e) {
      notify.error(`We could not refresh your transaction history. Please refresh the page upon closing this modal.`)
      disregard(e)
    }
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useBroadcastStakeIbcxMutation }
