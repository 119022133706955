import React, { useState } from 'react'
import { useAtomValue } from 'jotai'
import { Button } from '@mantine/core'
import { CHAIN_INFO_LIST, config, DYM_CHAIN_INFO, INJ_CHAIN_INFO, ISLM_CHAIN_INFO, STRIDE_CHAIN_INFO } from '@/config'
import { StepperModalContent, useStepperContext } from '@/components'
import { stakeAmountAtom } from '../atoms'
import { useStakeClassicModal } from './StakeClassicModalProvider'
import { STAKING_STEP_THREE } from './constants'
import { useSelectedCoin, useSelectedWallet, useStrideBalancesV2 } from '@/contexts/wallet'
import { useRateLimitQuery } from '../queries'
import { getRateLimitMetaData } from '../utils'
import { RateLimitClosedLiquidStakeModalWarning, RateLimitTightLiquidStakeModalWarning } from '../shared'
import { convertDenomToMicroDenom, formatDenom, formatMicroDenom } from '@/wallet-utils'

const StakeClassicModalStepOne: React.FC = () => {
  const amount = useAtomValue(stakeAmountAtom)

  const denom = useSelectedCoin()

  const selectedAccount = useSelectedWallet()

  const { data: rateLimit } = useRateLimitQuery()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const amountInMicroDenom = convertDenomToMicroDenom(amount, chainInfo.stakeCurrency.coinMinimalDenom)

  const rateLimitMetaData = getRateLimitMetaData({ amount: amountInMicroDenom.toString(), rateLimit })

  const [isRateLimitingEnabled, setIsRateLimitingEnabled] = useState(true)

  const { data: strideBalances } = useStrideBalancesV2()

  const chainBalanceOnStride = BigInt(strideBalances?.strideSelectedAccountBalance ?? '0')

  const { signIbcTransfer, isSigningIbcTransfer, signIbcTransferError, setIsIbcTransferSkipped } =
    useStakeClassicModal()

  // User has some tokens on Stride, however insufficient
  const isViableToApplyStrideBalance =
    chainBalanceOnStride >=
    convertDenomToMicroDenom(config.staking.strideBalanceThreshold, chainInfo.stakeCurrency.coinMinimalDenom)

  // User has enough tokens on Stride to fund the entire transaction
  const isViableToUseFullStrideBalance = chainBalanceOnStride >= amountInMicroDenom

  /// Temporarily, this is a workaround to make users use Injective Bridge for IBC transfers and allow them to keep staking
  const isIbcTransferringFromInjectiveLedger =
    denom === INJ_CHAIN_INFO.stakeCurrency.coinDenom && Boolean(selectedAccount?.isNanoLedger)

  // Temporarily, this is a workaround to prevent users from doing IBC transfers since Haqq does not support ledger as of the moment
  const isIbcTransferringFromHaqqLedger =
    denom === ISLM_CHAIN_INFO.stakeCurrency.coinDenom && Boolean(selectedAccount?.isNanoLedger)

  /// Temporarily, this is a workaround to make users use Dymension Bridge for IBC transfers and allow them to keep staking
  /// @TODO: Figure out how to detect if the current selected wallet is a Ledger Nano
  const isIbcTransferringFromDymension = denom === DYM_CHAIN_INFO.stakeCurrency.coinDenom

  const appliedStrideBalanceAmount = amountInMicroDenom - chainBalanceOnStride

  const { start, nextStep, jumpStep, forceClose } = useStepperContext()

  const handleStart = async (amount: bigint) => {
    start()
    await signIbcTransfer({ amount: amount.toString() })
    nextStep()
  }

  const handleStartTransfer = async () => {
    handleStart(amountInMicroDenom)
  }

  const handleApplyStrideBalance = () => {
    handleStart(appliedStrideBalanceAmount)
  }

  const handleUseFullStrideBalance = () => {
    setIsIbcTransferSkipped(true)
    start()
    jumpStep(STAKING_STEP_THREE)
  }

  const handleRetrySignSendToken = async () => {
    handleStart(amountInMicroDenom)
  }

  const handleDismissRateLimitingWarning = () => {
    setIsRateLimitingEnabled(false)
  }

  if (signIbcTransferError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetrySignSendToken}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningIbcTransfer) {
    return (
      <StepperModalContent
        title="Approve the transaction in your wallet to continue"
        description={`This will start the transfer of your ${denom} tokens to Stride to start the staking process.`}
      />
    )
  }

  if (isRateLimitingEnabled && rateLimitMetaData.status === 'tight') {
    return <RateLimitTightLiquidStakeModalWarning amount={amount} onContinue={handleDismissRateLimitingWarning} />
  }

  if (isRateLimitingEnabled && rateLimitMetaData.status === 'closed') {
    return <RateLimitClosedLiquidStakeModalWarning onContinue={handleDismissRateLimitingWarning} />
  }

  if (isViableToUseFullStrideBalance) {
    // User has enough tokens on Stride to fund the entire transaction
    return (
      <StepperModalContent
        title="Your existing Stride balance will be used for this stake"
        description={`Good news! You already have enough ${denom} on Stride to fund this stake, so staking can start without transferring additional tokens.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Cancel
            </Button>

            <Button onClick={handleUseFullStrideBalance}>Continue</Button>
          </>
        }
      />
    )
  }

  if (isIbcTransferringFromInjectiveLedger) {
    return (
      <StepperModalContent
        title="Send your tokens to Stride using the Injective Bridge"
        description={`Looks like you're using Ledger! To send your ${INJ_CHAIN_INFO.stakeCurrency.coinDenom} tokens to ${STRIDE_CHAIN_INFO.chainName}, you'll need to use the Injective Bridge, then you can liquid stake normally on ${STRIDE_CHAIN_INFO.chainName}.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" component="a" href={config.links.injectiveBridge}>
              Bridge
            </Button>
          </>
        }
      />
    )
  }

  if (isIbcTransferringFromHaqqLedger) {
    return (
      <StepperModalContent
        title="Unsupported"
        description="Ledger support is coming soon. Please try again."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
      />
    )
  }

  if (isIbcTransferringFromDymension) {
    return (
      <StepperModalContent
        title="Send your tokens to Stride using the Dymension IBC Bridge"
        description={`To send your ${DYM_CHAIN_INFO.stakeCurrency.coinDenom} tokens to ${STRIDE_CHAIN_INFO.chainName}, you'll need to use the Dymension IBC Bridge, then you can liquid stake normally on ${STRIDE_CHAIN_INFO.chainName}.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" component="a" href={config.links.dymensionBridge}>
              Bridge
            </Button>
          </>
        }
      />
    )
  }

  if (isViableToApplyStrideBalance) {
    // User can cover some of the transaction with their existing Stride balance, but not enough to fund the entire transaction
    // The rest of the transaction will be funded with IBC
    return (
      <StepperModalContent
        title={`Your existing Stride balance will be applied to this stake`}
        description={`You already have ${formatMicroDenom(
          chainBalanceOnStride,
          CHAIN_INFO_LIST[denom].stakeCurrency.coinMinimalDenom,
          3
        )} ${denom} on Stride. The remaining ${formatMicroDenom(
          appliedStrideBalanceAmount,
          CHAIN_INFO_LIST[denom].stakeCurrency.coinMinimalDenom,
          3
        )} ${denom} will be transferred to Stride.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Cancel
            </Button>

            <Button onClick={handleApplyStrideBalance}>Continue</Button>
          </>
        }
      />
    )
  }

  // User must fund the entire transaction with IBC and has no tokens on Stride
  // While Autopilot already exists, some chains have it disabled (like Injective
  // at the moment), so we'll keep this flow.
  return (
    <StepperModalContent
      title={`Nice! You’re about to liquid stake ${formatDenom(amount, 3)} ${denom} on Stride`}
      description="This should take about a minute and will require 2 wallet approvals"
      actions={<Button onClick={handleStartTransfer}>Start staking</Button>}
    />
  )
}

export { StakeClassicModalStepOne }
