import React, { useMemo } from 'react'
import { Button } from '@mantine/core'
import { StepperModalContent, useStepperContext } from '@/components'
import { useWithdrawLsmModal } from './WithdrawLsmModalProvider'
import { useMount } from '@/hooks'
import { LsmValidatorName } from '../shared'
import { getValidatorAddressFromLsmTokenizedShare } from '../utils'
import { useStake } from '../StakeProvider'
import { notify } from '@/contexts/notifications'
import { useSelectedCoin } from '@/contexts/wallet'
import { StandardTransactionError, formatMicroDenom } from '@/wallet-utils'
import { CHAIN_INFO_LIST } from '@/config'

const WithdrawLsmModalStepTwo: React.FC = () => {
  const {
    signRedeemTokenForShares,
    signRedeemTokenForSharesError,
    broadcastRedeemTokenForShares,
    isBroadcastingRedeemTokenForShares,
    isBroadcastRedeemTokenForSharesSuccess,
    broadcastRedeemTokenForSharesError
  } = useWithdrawLsmModal()

  const { complete, handleClose, forceClose, close } = useStepperContext()

  const denom = useSelectedCoin()

  const { withdrawLsm } = useStake()

  const validatorAddress = useMemo(() => {
    return getValidatorAddressFromLsmTokenizedShare(withdrawLsm.txHistoryIbcTokenizedDenom)
  }, [withdrawLsm])

  const handleBroadcast = async () => {
    await broadcastRedeemTokenForShares()
    complete()
  }

  const handleStep = async () => {
    await signRedeemTokenForShares()
    await handleBroadcast()
  }

  useMount(() => {
    handleStep()
  })

  const handleCloseCallback = () => {
    // If it was closed after the broadcast, we don't need to prepend.
    if (!isBroadcastingRedeemTokenForShares) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the staking completes.")
  }

  handleClose(handleCloseCallback)

  const chainInfo = CHAIN_INFO_LIST[denom]

  const formattedAmount = formatMicroDenom(withdrawLsm.amount, chainInfo.stakeCurrency.coinMinimalDenom, 3)

  if (isBroadcastRedeemTokenForSharesSuccess) {
    return (
      <StepperModalContent
        title={`Success!`}
        description={`Your ${formattedAmount} ${denom} has been restored to your previous staked position.`}
        actions={<Button onClick={close}>Done</Button>}
      />
    )
  }

  if (broadcastRedeemTokenForSharesError instanceof StandardTransactionError) {
    // Generally, INSUFFICIENT_FUNDS (code 5) and OUT_OF_GAS (code 11) can mean the same thing.
    // We're also not sure if tokenized shares can be used as gas if there's not enough unstaked
    // or staked ATOM.
    //
    // https://github.com/Stride-Labs/interface/issues/529
    // @TODO: When we try fixing how retry broadcast works, it's possible that when their tokenized
    // shares are used as gas, they'll have less, and so we might want to cover for that.
    const isOutOfGas =
      broadcastRedeemTokenForSharesError.description === 'INSUFFICIENT_FUNDS' ||
      broadcastRedeemTokenForSharesError.description === 'OUT_OF_GAS'

    // @TODO: Update out of gas message to be more friendly and relevant
    const description = isOutOfGas
      ? `Transaction failed due to out of gas. Please try again.`
      : broadcastRedeemTokenForSharesError.message

    return (
      <StepperModalContent
        title={`Transaction error`}
        description={description}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
        error={broadcastRedeemTokenForSharesError}
      />
    )
  }

  if (broadcastRedeemTokenForSharesError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description="This redemption could not be completed. Your tokenized balances have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isBroadcastingRedeemTokenForShares) {
    return (
      <StepperModalContent
        title={`Redeeming ${denom}...`}
        description={`Hang tight, we're restoring your previous staked position.`}
      />
    )
  }

  if (signRedeemTokenForSharesError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description="This redemption could not be completed. Your tokenized balances have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStep}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={`Approve the transaction in your wallet to continue`}
      description={
        <>
          This will restore your previous staked position with the amount of {formattedAmount} {denom} to{' '}
          <LsmValidatorName address={validatorAddress} />.
        </>
      }
    />
  )
}

export { WithdrawLsmModalStepTwo }
