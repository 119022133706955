import { StepperModalContent, useStepperContext } from '@/components'
import { CHAIN_INFO_LIST, DEX_CONFIG, DEX_INFO_LIST } from '@/config'
import { assert } from '@/utils'
import { Button } from '@mantine/core'
import React from 'react'
import { useSelectedCoin } from '@/contexts/wallet'

const WithdrawStTokenToDexStepTwo: React.FC = () => {
  // We'll intentionally use `forceClose` instead of `close` because technically this
  // flow will never be "complete" (the previous step doesn't call the `complete` method)
  const { forceClose } = useStepperContext()

  const denom = useSelectedCoin()

  const dexConfig = DEX_CONFIG[denom]

  assert(dexConfig, `Missing dex config for ${denom}`)

  const dexInfo = DEX_INFO_LIST[dexConfig.type]

  const poolUrl = dexInfo.poolUrl(dexConfig.poolId)

  const chainInfo = CHAIN_INFO_LIST[dexInfo.coinDenom]

  return (
    <StepperModalContent
      title={`Add your tokens to the pool`}
      description={`Your st${denom} has been transferred to ${chainInfo.chainName}. You can now provide liquidity or trade on ${dexInfo.name}. Remember to bond your shares to earn rewards, if you provide liquidity.`}
      actions={
        <>
          <Button variant="outline" color="dark" onClick={forceClose}>
            Exit
          </Button>

          <Button component="a" href={poolUrl} target="_blank">
            Go to {dexInfo.name}
          </Button>
        </>
      }
    />
  )
}

export { WithdrawStTokenToDexStepTwo }
