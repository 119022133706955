import { useMutation } from '@tanstack/react-query'
import { CHAIN_CONFIG } from '@/config'
import {
  generateIbcTimeoutTimestamp,
  isSafeModeAccount,
  simulate,
  calculateFeeWithHelper,
  deductFeeFromTxAmountWhileEmptyStrd
} from '@/wallet-utils'
import { assert } from '@/utils'
import { useLiquidStakeTransactionAmountQuery } from '../queries'
import { MutationParameters } from './types'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useDexWallet, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

const useSignWithdrawStTokenToDexMutation = ({ setWithdrawStTokenToDexRaw }: MutationParameters) => {
  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()
  const dexAccount = useDexWallet()

  const { data: stakeTransactionAmount } = useLiquidStakeTransactionAmountQuery()

  const handleMutation = async (): Promise<TxRaw> => {
    assert(strideAccount, 'You are unable to send token without connecting your Stride wallet.')
    assert(selectedAccount, 'You are unable to send token without connecting your selected wallet.')
    assert(dexAccount, 'You are unable to send token without connecting your dex wallet.')
    assert(!isSafeModeAccount(strideAccount), 'Safe mode is enabled.')
    assert(stakeTransactionAmount, 'Unable to sign ibc transfer to dex while transaction amount is null.')

    const createMsgTransfer = (amount: string) => {
      return {
        typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
        value: {
          sourcePort: 'transfer',
          sourceChannel: CHAIN_CONFIG[dexAccount.currency.coinDenom].withdrawChannel,
          token: {
            amount,
            denom: `st${selectedAccount.currency.coinMinimalDenom}`
          },
          sender: strideAccount.address,
          receiver: dexAccount.address,
          memo: '',
          timeoutTimestamp: generateIbcTimeoutTimestamp()
        }
      }
    }

    const msgTransferForSimulation = createMsgTransfer(stakeTransactionAmount.amount)
    const gas = await simulate(strideAccount.client, strideAccount.address, [msgTransferForSimulation], '')
    const { fee, deductible } = calculateFeeWithHelper(selectedAccount.currency.coinMinimalDenom, gas)

    // Allot a small portion of the user's tokens for the transaction gas fee
    //
    // Testing Guide (Preparation):
    // (Make sure you are testing with a chain that has "Add to DEX" enabled like Cosmos Hub or Saga)
    // 1. Setup a fresh account, and send a small amount of tokens to it (e.g., 1 SAGA).
    // 2. Liquid Stake then proceed with "Add to DEX" flow
    // 3. Proceed with "Withdraw St Token" flow.
    // 4. Inspect the transaction amount and you'll find that it should be less than the full amount.
    //
    // @TODO: Only deduct if the user does not have enough tokens for gas
    const amountWithSafetyDeduction = deductFeeFromTxAmountWhileEmptyStrd(
      strideAccount.coins,
      BigInt(stakeTransactionAmount.amount),
      deductible
    )

    const msgTransfer = createMsgTransfer(amountWithSafetyDeduction.toString())

    return await strideAccount.client.sign(strideAccount.address, [msgTransfer], fee, '')
  }

  const handleSuccess = (raw: TxRaw) => {
    flushSync(() => {
      setWithdrawStTokenToDexRaw(raw)
    })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

export { useSignWithdrawStTokenToDexMutation }
