import React, { useState } from 'react'
import { useAtomValue } from 'jotai'
import { Button } from '@mantine/core'
import BigNumber from 'bignumber.js'
import { StepperModalContent, useStepperContext } from '@/components'
import { CHAIN_INFO_LIST, INJ_CHAIN_INFO, ISLM_CHAIN_INFO, STRIDE_CHAIN_INFO, config } from '@/config'
import { stakeAmountAtom } from '../atoms'
import { useStakeAutopilotModal } from './StakeAutopilotModalProvider'
import { useSelectedCoin, useSelectedWallet } from '@/contexts/wallet'
import { RateLimitTightLiquidStakeModalWarning, RateLimitClosedLiquidStakeModalWarning } from '../shared'
import { getRateLimitMetaData } from '../utils'
import { useRateLimitQuery } from '../queries'
import { convertDenomToMicroDenom } from '@/wallet-utils'

const format = (value: number) => {
  return new BigNumber(value).decimalPlaces(3).toFormat()
}

const StakeAutopilotModalStepOne: React.FC = () => {
  const amount = useAtomValue(stakeAmountAtom)

  const denom = useSelectedCoin()

  const selectedAccount = useSelectedWallet()

  const { data: rateLimit } = useRateLimitQuery()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const amountInMicroDenom = convertDenomToMicroDenom(amount, chainInfo.stakeCurrency.coinMinimalDenom)

  const rateLimitMetaData = getRateLimitMetaData({ amount: amountInMicroDenom.toString(), rateLimit })

  const [isRateLimitingEnabled, setIsRateLimitingEnabled] = useState(true)

  const { signStakeAutopilot, isSigningStakeAutopilot, signStakeAutopilotError } = useStakeAutopilotModal()

  /// Temporarily, this is a workaround to make users use Injective Bridge for IBC transfers and allow them to keep staking
  /// @TODO: Figure out how to detect if the current selected wallet is a Ledger Nano
  const isIbcTransferringFromInjectiveLedger = selectedAccount
    ? selectedAccount.currency.coinDenom === INJ_CHAIN_INFO.stakeCurrency.coinDenom && selectedAccount.isNanoLedger
    : false

  const isIbcTransferringFromHaqqLedger = selectedAccount
    ? selectedAccount.currency.coinDenom === ISLM_CHAIN_INFO.stakeCurrency.coinDenom && selectedAccount.isNanoLedger
    : false

  const { start, nextStep, forceClose } = useStepperContext()

  const handleStart = async () => {
    start()
    await signStakeAutopilot()
    nextStep()
  }

  const handleStartTransfer = async () => {
    handleStart()
  }

  const handleRetrySignSendToken = async () => {
    handleStart()
  }

  const handleDismissRateLimitingWarning = () => {
    setIsRateLimitingEnabled(false)
  }

  if (signStakeAutopilotError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetrySignSendToken}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningStakeAutopilot) {
    return (
      <StepperModalContent
        title="Approve the transaction in your wallet to continue"
        description={`This will start the transfer of your ${denom} tokens to Stride to start the staking process.`}
      />
    )
  }

  if (isRateLimitingEnabled && rateLimitMetaData.status === 'tight') {
    return <RateLimitTightLiquidStakeModalWarning amount={amount} onContinue={handleDismissRateLimitingWarning} />
  }

  if (isRateLimitingEnabled && rateLimitMetaData.status === 'closed') {
    return <RateLimitClosedLiquidStakeModalWarning onContinue={handleDismissRateLimitingWarning} />
  }

  if (isIbcTransferringFromInjectiveLedger) {
    return (
      <StepperModalContent
        title="Send your tokens to Stride using the Injective Bridge"
        description={`Looks like you're using Ledger! To send your ${INJ_CHAIN_INFO.stakeCurrency.coinDenom} tokens to ${STRIDE_CHAIN_INFO.chainName}, you'll need to use the Injective Bridge, then you can liquid stake normally on ${STRIDE_CHAIN_INFO.chainName}.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" component="a" href={config.links.injectiveBridge}>
              Bridge
            </Button>
          </>
        }
      />
    )
  }

  if (isIbcTransferringFromHaqqLedger) {
    return (
      <StepperModalContent
        title="Unsupported"
        description="Ledger support is coming soon. Please try again."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={`Nice! You’re about to liquid stake ${format(amount)} ${denom} on Stride`}
      description="This should take around a minute."
      actions={<Button onClick={handleStartTransfer}>Start staking</Button>}
    />
  )
}

export { StakeAutopilotModalStepOne }
