import dynamic from 'next/dynamic'
import { Container, Paper, Tabs, Stack, Tooltip, useMantineTheme } from '@mantine/core'
import { StakeCoinSelector } from './StakeCoinSelector'
import { StakeForm, useStakeForm } from './StakeForm'
import { StakeLsmModal, StakeLsmModalProvider } from './StakeLsmModal'
import { StakeClassicModal, StakeClassicModalProvider } from './StakeClassicModal'
import { StakeAutopilotModal, StakeAutopilotModalProvider } from './StakeAutopilotModal'
import { StakeStats } from './StakeStats'
import { UnstakeForm, useUnstakeForm } from './UnstakeForm'
import { UnstakeModal, UnstakeModalProvider } from './UnstakeModal'
import { WithdrawStTokenToDexModal, WithdrawStTokenToDexProvider } from './WithdrawStTokenToDexModal'
import { StakeTransactionHistory } from './StakeTransactionHistory'
import { WithdrawModal } from './WithdrawModal'
import { WithdrawStTokenModal, WithdrawStTokenProvider } from './WithdrawStTokenModal'
import { WithdrawLsmModal, WithdrawLsmModalProvider } from './WithdrawLsmModal'
import { WithdrawLsmFromTokenizeModal, WithdrawLsmFromTokenizeModalProvider } from './WithdrawLsmFromTokenizeModal'
import { LsmTutorialModal } from './LsmTutorialModal'
import { StakeFeeTokensModal } from './StakeFeeTokensModal'
import { StakeChainHaltedCard } from './StakeChainHaltedCard'

import { CHAIN_SUPPORTS_LSM } from '@/config'
import { useAutoFocusRef } from '@/hooks'
import { FormProvider } from '@/components/Form'
import { useMediaQuery } from '@mantine/hooks'
import { PoolNudgeModal } from './PoolNudgeModal'
import { useStake } from './StakeProvider'
import { useLsmValidatorsQuery, useRateLimitQuery } from './queries'
import { getTotalNativelyStakedBalance } from './utils'
import { useSelectedCoin } from '@/contexts/wallet'
import { StakeDydxBanner } from './StakeDydxBanner'
import { StakeTiaSwapBanner } from './StakeTiaSwapBanner'
import { StakeDymBanner } from './StakeDymBanner'
import { FEATURE_FLAGS } from '@/feature-flags'

const StakeWallet = dynamic<{}>(() => import('./StakeWallet').then((module) => module.StakeWallet), { ssr: false })

const Stake: React.FC = () => {
  const { lsmTutorialTooltip, setMode, setLsmValidatorAddress } = useStake()

  const { data: lsmValidators } = useLsmValidatorsQuery()

  // We want to make sure rate limit is preloaded
  useRateLimitQuery()

  const denom = useSelectedCoin()

  const stakeForm = useStakeForm()

  const unstakeForm = useUnstakeForm()

  const stakeAmountInputRef = useAutoFocusRef()

  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  const handleDenomChange = () => {
    // We want to make sure the amount field is reset when the user switches to another chain.
    stakeForm.reset()

    if (!CHAIN_SUPPORTS_LSM[denom]) {
      // We want to switch back to default mode if the chain we're switching to doesn't support LSM.
      setMode('default')
    }

    // We want to make sure previously selected validator is unselected when user switches to another chain.
    setLsmValidatorAddress('')
  }

  const handleStakeLsmSuccess = () => {
    // I have a hunch there will be a bug here when we do the integration because
    // lsmValidators will have stale values when we get here, I think. We'll see.
    // If the user has no natively staked balance, we should switch back to the default mode.
    if (!getTotalNativelyStakedBalance({ lsmValidators })) {
      setMode('default')
    }

    stakeForm.reset()
  }

  const handleStakeDefaultSuccess = () => {
    stakeForm.reset()
  }

  const handleClaimSuccess = () => {
    unstakeForm.reset()
  }

  return (
    <>
      {/* <Container size="sm" px={isMediumScreen ? 'lg' : 'sm'} pt={isMediumScreen ? 'xl' : 'md'}> */}
      <Container size="sm" px={isMediumScreen ? 'xs' : 'sm'} pt={isMediumScreen ? 'xl' : 'md'}>
        <StakeCoinSelector onChange={handleDenomChange} />

        <Stack spacing="md">
          <StakeChainHaltedCard />

          <StakeTransactionHistory />

          <StakeDydxBanner />

          <StakeTiaSwapBanner />

          <StakeDymBanner />

          <Tooltip
            opened={lsmTutorialTooltip.isOpen}
            label="Select the validator that you want to liquid stake from"
            withinPortal
            wrapLines
            withArrow
            position="right"
            width={220}>
            <Paper p={isMediumScreen ? 48 : 'md'}>
              {FEATURE_FLAGS.TESTNET ? (
                <FormProvider {...stakeForm}>
                  <StakeForm amountInputRef={stakeAmountInputRef} />
                </FormProvider>
              ) : (
                <Tabs
                  tabPadding="md"
                  styles={(t) => ({
                    tabControl: {
                      color: t.colors.gray[9]
                    },
                    tabActive: {
                      color: t.colors.gray[9]
                    }
                  })}>
                  <Tabs.Tab label="Stake">
                    <FormProvider {...stakeForm}>
                      <StakeForm amountInputRef={stakeAmountInputRef} />
                    </FormProvider>
                  </Tabs.Tab>
                  <Tabs.Tab label="Unstake">
                    <FormProvider {...unstakeForm}>
                      <UnstakeForm />
                    </FormProvider>
                  </Tabs.Tab>
                </Tabs>
              )}
            </Paper>
          </Tooltip>

          <StakeWallet />

          <StakeStats />
        </Stack>
      </Container>

      <LsmTutorialModal />

      <StakeLsmModalProvider>
        <StakeLsmModal onSuccess={handleStakeLsmSuccess} />
      </StakeLsmModalProvider>

      <StakeClassicModalProvider>
        <StakeClassicModal onSuccess={handleStakeDefaultSuccess} />
      </StakeClassicModalProvider>

      <StakeAutopilotModalProvider>
        <StakeAutopilotModal onSuccess={handleStakeDefaultSuccess} />
      </StakeAutopilotModalProvider>

      <UnstakeModalProvider>
        <UnstakeModal onSuccess={handleClaimSuccess} />
      </UnstakeModalProvider>

      <PoolNudgeModal />

      <WithdrawModal />

      <WithdrawStTokenToDexProvider>
        <WithdrawStTokenToDexModal />
      </WithdrawStTokenToDexProvider>

      <WithdrawStTokenProvider>
        <WithdrawStTokenModal />
      </WithdrawStTokenProvider>

      <WithdrawLsmModalProvider>
        <WithdrawLsmModal />
      </WithdrawLsmModalProvider>

      <WithdrawLsmFromTokenizeModalProvider>
        <WithdrawLsmFromTokenizeModal />
      </WithdrawLsmFromTokenizeModalProvider>

      <StakeFeeTokensModal />
    </>
  )
}

export { Stake }
