import { useMutation, useQueryClient } from '@tanstack/react-query'
import { fatal, disregard } from '@/utils'
import { notify } from '@/contexts/notifications'
import { traceIbcStatus, IBCTransferStatus, isSafeModeAccount } from '@/wallet-utils'
import { queryKeys } from '@/query-keys'
import { MutationParameters } from './types'
import { useChainWallet, useRefreshStrideBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

// [sign/broadcast] mutations and [trace] mutation are a 2-part process.
// First, if [sign/broadcast] doesn't succeed within the UI time limit, [trace] mutation will continue
// the [tracing] process so user can continue when the ibc transfer completes if they haven't closed the modal yet.
// We can use the `isLoading` status provided by [trace] mutation to display the "Return Later" screen.
const useTraceIbcTransfer = ({ withdrawStTokenToDexTransaction }: MutationParameters) => {
  const client = useQueryClient()

  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const { data: osmosisAccount } = useChainWallet('OSMO')

  const refreshStrideBalances = useRefreshStrideBalances()

  return useMutation({
    mutationFn: async (): Promise<{ status: IBCTransferStatus }> => {
      if (!strideAccount || !selectedAccount || !osmosisAccount) {
        throw fatal('You are unable to send token without connecting your wallet.')
      }

      if (!withdrawStTokenToDexTransaction) {
        throw fatal('Unable to trace ibc status while transaction is empty.')
      }

      if (isSafeModeAccount(strideAccount)) throw fatal('Safe mode is enabled.')

      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      const status = await traceIbcStatus(
        { account: strideAccount, tx: withdrawStTokenToDexTransaction },
        { timeout: false }
      )

      await client.invalidateQueries({
        queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: withdrawStTokenToDexTransaction.transactionHash })
      })

      try {
        await refreshStrideBalances()
      } catch (e) {
        notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
        disregard(e)
      }

      await client.refetchQueries('dexAccount')

      return { status }
    }
  })
}

export { useTraceIbcTransfer }
