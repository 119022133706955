import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { assertIsDeliverTxSuccess, broadcastTx } from '@/wallet-utils'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { queryKeys } from '@/query-keys'
import { fatal, disregard } from '@/utils'
import { notify } from '@/contexts/notifications'
import { traceIbcStatus, IBCTransferStatus, isSafeModeAccount } from '@/wallet-utils'
import { MutationParameters } from './types'
import { useRefreshBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

export interface BroadcastWithdrawStTokenMutationReturnType {
  status: IBCTransferStatus
  transaction: DeliverTxResponse
}

const useBroadcastWithdrawStTokenMutation = ({
  withdrawStTokenRaw,
  setWithdrawStTokenTransaction
}: MutationParameters) => {
  const client = useQueryClient()

  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const refreshBalances = useRefreshBalances()

  const handleMutation = async (): Promise<BroadcastWithdrawStTokenMutationReturnType> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!withdrawStTokenRaw) {
      throw fatal('Unable to broadcast empty signed transaction.')
    }

    const bytes = TxRaw.encode(withdrawStTokenRaw).finish()

    // If the stride account is in safe mode, throw an error
    if (isSafeModeAccount(strideAccount)) {
      throw fatal('Stride account is in safe mode')
    }

    const transaction = await broadcastTx(strideAccount.client, bytes)

    await client.invalidateQueries({ queryKey: queryKeys.transactionHistorBase })

    assertIsDeliverTxSuccess(transaction)

    const status = await traceIbcStatus({ account: strideAccount, tx: transaction })

    // @TODO: Handle if ibc status === 'timeout'. We likely need a flow for this.
    return { status, transaction }
  }

  const handleSuccess = async ({ transaction }: BroadcastWithdrawStTokenMutationReturnType) => {
    setWithdrawStTokenTransaction(transaction)

    await client.invalidateQueries({
      queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: transaction.transactionHash })
    })

    await client.invalidateQueries({
      queryKey: queryKeys.stakingRateLimit
    })

    try {
      await refreshBalances()
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useBroadcastWithdrawStTokenMutation }
