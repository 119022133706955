import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { fatal } from '@/utils'
import { CHAIN_CONFIG } from '@/config'
import { convertDenomToMicroDenom, isSafeModeAccount } from '@/wallet-utils'
import { generateIbcTimeoutTimestamp, signIbcTransaction, IbcReturnType } from '@/wallet-utils/ibc'
import { stakeAmountAtom } from '../../atoms'
import { MutationParameters } from './types'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { useReferral } from '@/page-components/Referral'

const useSignStakeAutopilotMutation = ({ setStakeAutopilotRaw }: MutationParameters) => {
  const { memo } = useReferral()

  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const amount = useAtomValue(stakeAmountAtom)

  const handleMutation = async (): Promise<IbcReturnType> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    const amountInMicroDenom = convertDenomToMicroDenom(amount, selectedAccount.currency.coinMinimalDenom)

    if (isSafeModeAccount(selectedAccount)) throw fatal('Safe mode is enabled.')

    const receiver = JSON.stringify({
      autopilot: {
        stakeibc: {
          stride_address: strideAccount.address,
          action: 'LiquidStake'
        },
        receiver: strideAccount.address
      }
    })

    const msgTransfer = {
      typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
      value: {
        sourcePort: 'transfer',
        sourceChannel: CHAIN_CONFIG[selectedAccount.currency.coinDenom].depositChannel,
        token: {
          amount: amountInMicroDenom.toString(),
          denom: selectedAccount.currency.coinMinimalDenom
        },
        sender: selectedAccount.address,
        receiver,
        memo: '',
        timeoutTimestamp: generateIbcTimeoutTimestamp()
      }
    }

    const fee = {
      amount: [
        {
          denom: selectedAccount.currency.coinMinimalDenom,
          amount: '25000000000000000'
        }
      ],
      gas: '1000000'
    }

    return await signIbcTransaction(selectedAccount, selectedAccount.address, [msgTransfer], fee, memo)
  }

  const handleSuccess = (raw: IbcReturnType) => {
    setStakeAutopilotRaw(raw)
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useSignStakeAutopilotMutation }
