import { OSMO_CHAIN_INFO } from '@/config'
import { IBCX_CURRENCY } from '@/config/ibcx'
import { queryKeys } from '@/query-keys'
import { Int } from '@keplr-wallet/unit'
import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { fatal } from '@/utils'
import { CoreQueryClient } from '@many-things/ibcx-contracts-sdk/types/contracts/Core.client'
import { PoolRaw, useOsmosisPoolsQuery } from './useOsmosisPoolsQuery'
import { getOsmosisOptimizedRoutes } from './osmosis-pools-utils'
import { getStIbcxCoreQueryClient, getIbcxCoreQueryClient } from './query-clients'

interface GetOsmosisSwapRouteArgs {
  rawPools: PoolRaw[]
  coreQueryClient: CoreQueryClient
}

const getOptimizedSwapRouteAboutIbcxPortfolioAsset = async ({ rawPools, coreQueryClient }: GetOsmosisSwapRouteArgs) => {
  const optimizedRoutes = getOsmosisOptimizedRoutes(rawPools)

  const portfolio = await coreQueryClient.getPortfolio({})

  const routes = portfolio.units.map(([minimalDenom, amount]) => {
    const moveDecimalPointRightSixAmount = new BigNumber(amount)
      .multipliedBy(`1e+${IBCX_CURRENCY.coinDecimals}`)
      .decimalPlaces(0, 0)
      .toString()

    const route = optimizedRoutes.getOptimizedRoutesByTokenIn(
      { denom: minimalDenom, amount: new Int(moveDecimalPointRightSixAmount) },
      OSMO_CHAIN_INFO.stakeCurrency.coinMinimalDenom,
      5
    )

    return {
      minimalDenom,
      route,
      calculatedOsmoOutput: optimizedRoutes.calculateTokenOutByTokenIn(route).amount.toString()
    }
  })

  return routes
}

export const useIbcxTokensSwapRoutesQuery = () => {
  const { data: osmosisPools, isLoading: isOsmosisPoolQueryLoading } = useOsmosisPoolsQuery()

  const handleRequest = async () => {
    if (osmosisPools === undefined) {
      throw fatal('Unable to get ibcx tokens swap routes')
    }

    const [stIbcxCoreQueryClient, ibcxCoreQueryClient] = await Promise.all([
      getStIbcxCoreQueryClient(),
      getIbcxCoreQueryClient()
    ])

    const [stIbcxRoutes, ibcxRoutes] = await Promise.all([
      getOptimizedSwapRouteAboutIbcxPortfolioAsset({
        rawPools: osmosisPools.pools,
        coreQueryClient: stIbcxCoreQueryClient
      }),

      getOptimizedSwapRouteAboutIbcxPortfolioAsset({
        rawPools: osmosisPools.pools,
        coreQueryClient: ibcxCoreQueryClient
      })
    ])

    return { stIbcxRoutes, ibcxRoutes }
  }

  const { isLoading, ...rest } = useQuery({
    queryKey: queryKeys.ibcxTokensSwapRoutesQuery, 
    queryFn: andleRequest,
    enabled: osmosisPools !== undefined
  })

  return {
    ...rest,
    isLoading: isLoading || isOsmosisPoolQueryLoading
  }
}
