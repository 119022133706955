import z from 'zod'
import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { MetaDataHandlerParameters } from './types'

const handleIbcLiquidStakeAutopilot = ({
  message,
  strideAccount,
  selectedAccount
}: MetaDataHandlerParameters<MsgTransfer>): boolean => {
  if (message.token == null) {
    return false
  }

  if (message.token.denom.startsWith('st')) {
    return false
  }

  const result = parseReceiverData(message)

  if (!result.success) {
    return false
  }

  return (
    message.sender === selectedAccount.address &&
    result.data.autopilot.receiver === strideAccount.address &&
    message.token.denom === selectedAccount.currency.coinMinimalDenom
  )
}

const schema = z.object({
  autopilot: z.object({
    stakeibc: z.object({
      stride_address: z.string(),
      action: z.literal('LiquidStake')
    }),
    receiver: z.string()
  })
})

const parseReceiverData = (message: MsgTransfer) => {
  const parse = () => {
    try {
      return JSON.parse(message.receiver)
    } catch (e) {
      return ''
    }
  }

  return schema.safeParse(parse())
}

export { handleIbcLiquidStakeAutopilot }
