import React from 'react'
import { useAtomValue } from 'jotai'
import { Button } from '@mantine/core'
import { useMount } from '@/hooks'
import { StepperModalContent, useStepperContext } from '@/components'
import { notify } from '@/contexts/notifications'
import { claimMetaDataAtom } from '@/page-components/Stake/atoms'
import { useUnstakeModal } from './UnstakeModalProvider'
import { useSelectedCoin } from '@/contexts/wallet'
import { StandardTransactionError, formatMicroDenom } from '@/wallet-utils'

const UnstakeModalStepFour: React.FC = () => {
  const { claimTokens, isClaimingTokens, claimTokensError } = useUnstakeModal()

  const denom = useSelectedCoin()

  // Instead of a null check, we'll render claimMetaData using optional chaining (claimMeta?.values.amount)
  // This step is still rendered for at least one frame when we close the modal (which resets the claim atom).
  // To solve, it would be good to introduce an `onCloseComplete` callback to the stepper modal.
  // @TODO: https://github.com/Stride-Labs/interface/issues/249
  const claimMetaData = useAtomValue(claimMetaDataAtom)

  // const stakedDenom = `st${denom}`

  const { start, forceClose, close, handleClose, complete } = useStepperContext()

  const handleStep = async () => {
    start()

    await claimTokens()

    complete()
  }

  const handleCloseCallback = () => {
    // If the broadcast completed, it doesn't matter
    if (!isClaimingTokens) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the claim completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  const handleRetrySignStakeTokens = () => {
    handleStep()
  }

  if (claimTokensError instanceof StandardTransactionError) {
    // Generally, INSUFFICIENT_FUNDS (code 5) and OUT_OF_GAS (code 11) can mean the same thing.
    const isOutOfGas =
      claimTokensError.description === 'INSUFFICIENT_FUNDS' || claimTokensError.description === 'OUT_OF_GAS'

    // @TODO: Update out of gas message to be more friendly and relevant
    const description = isOutOfGas
      ? `Transaction failed due to out of gas. Please try again.`
      : claimTokensError.message

    return (
      <StepperModalContent
        title="Transaction error"
        description={description}
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" onClick={handleRetrySignStakeTokens}>
              Try again
            </Button>
          </>
        }
        error={claimTokensError}
      />
    )
  }

  if (claimTokensError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Your tokens couldn’t be claimed at this time. Try again in a few minutes."
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" onClick={handleRetrySignStakeTokens}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  // claimMetaData becomes empty while the modal closes
  const formattedAmount = claimMetaData
    ? `${formatMicroDenom(claimMetaData.values.amount, claimMetaData.values.minimalDenom, 5)} ${
        claimMetaData.values.denom
      }`
    : `0 ${denom}`

  if (isClaimingTokens) {
    return (
      <StepperModalContent
        title="Approve transaction in your wallet to claim your tokens"
        description={`This will transfer ${formattedAmount} back to your wallet and the unstaking process will be complete.`}
      />
    )
  }

  return (
    <StepperModalContent
      title="Claim successful!"
      description={`You’ve successfully unstaked ${formattedAmount}`}
      // @TODO: Let's add this back once we have the correct value for 33.289 - this will be calculated with claimMetaData.values.amount * RedemptionRate
      // which we already have on `StakeEstimate` component
      // description={`You’ve successfully unstaked ${claimMetaData.values.amount} ${stakedDenom} and 33.289 ${denom} has been added to your wallet`}
      actions={<Button onClick={close}>Done</Button>}
    />
  )
}

export { UnstakeModalStepFour }
