import React from 'react'
import { useAtomValue } from 'jotai'
import { Anchor, Box, Button, Space } from '@mantine/core'
import { useLatestValue, useMount } from '@/hooks'
import { CHAIN_INFO_LIST } from '@/config'
import { notify } from '@/contexts/notifications'
import { StepperModalContent, useStepperContext } from '@/components'
import { withdrawStTokenModalIsOpenAtom } from '../atoms'
import { useWithdrawStToken } from './WithdrawStTokenContext'
import { useSelectedCoin } from '@/contexts/wallet'
import { StandardTransactionError, formatMicroDenom } from '@/wallet-utils'
import { RATE_LIMIT_DOCS_URL } from '../constants'
import { RateLimitTimeUntilReset } from '../shared'

const WithdrawStTokenStepTwo: React.FC = () => {
  const denom = useSelectedCoin()

  const isOpen = useAtomValue(withdrawStTokenModalIsOpenAtom)

  const isOpenRef = useLatestValue(isOpen)

  const {
    signWithdrawStTokenData,
    broadcastWithdrawStToken,
    isBroadcastingWithdrawStToken,
    isBroadcastWithdrawStTokenSuccess,
    broadcastWithdrawStTokenError,
    traceIbcStatus,
    isIbcStatusLoading,
    resetWithdrawStTokenState
  } = useWithdrawStToken()

  const { reinitialize, complete, handleClose, forceClose, close } = useStepperContext()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const handleRetryFromRateLimiting = () => {
    resetWithdrawStTokenState()
    reinitialize()
  }

  const handleStep = async () => {
    const { status } = await broadcastWithdrawStToken()

    if (!isOpenRef.current) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler.
      return
    }

    if (status === 'return-later') {
      // If we're here, it means that our ibc transfer took too long and we're tracking it
      // the second time without the 30-second timeout. This way, the user can continue the
      // flow if it they haven't closed.
      //
      // We can check for the return-later status by checking using `isIbcStatusLoading`
      // to check if the ibc transfer hasn't completed and ibc tracking is still on-going.
      //
      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      // `status === 'timeout'` for both broadcast and tracing and `ibcStatusError`
      await traceIbcStatus()
    }

    complete()
  }

  useMount(() => {
    handleStep()
  })

  const handleRetry = () => {
    handleStep()
  }

  const handleCloseCallback = () => {
    // If it was closed after the broadcast, we don't need to prepend.
    if (!isBroadcastingWithdrawStToken && !isIbcStatusLoading) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the transfer completes.")
  }

  handleClose(handleCloseCallback)

  // We're not fatal-ing out here because `signWithdrawStTokenData` naturally gets reset
  // (becomes undefined) when the modal gets closed.
  const amount = signWithdrawStTokenData?.amount ?? '0'

  if (isIbcStatusLoading) {
    return (
      <StepperModalContent
        title="This transaction is taking longer than usual"
        description={`If you don’t want to wait here, you can close this dialog. Your transaction will continue and you’ll be able to see status on the Stride app page.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
      />
    )
  }

  if (
    broadcastWithdrawStTokenError instanceof StandardTransactionError &&
    broadcastWithdrawStTokenError.description === 'RATE_LIMIT_QUOTA_EXCEEDED'
  ) {
    return (
      <StepperModalContent
        title="Transaction failed due to rate limiting."
        description={
          <>
            <Box>
              You can try again now or come back once the rate limits have been reset. You can learn more about this{' '}
              <Anchor href={RATE_LIMIT_DOCS_URL} target="_blank">
                here
              </Anchor>
              .
            </Box>

            <Space h="xs" />

            <Box>
              <RateLimitTimeUntilReset />
            </Box>
          </>
        }
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Close
            </Button>

            <Button onClick={handleRetryFromRateLimiting}>Try again</Button>
          </>
        }
      />
    )
  }

  if (
    broadcastWithdrawStTokenError instanceof StandardTransactionError &&
    broadcastWithdrawStTokenError.description === 'INSUFFICIENT_FUNDS'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            Insufficient funds for gas. Get STRD, ATOM or TIA on{' '}
            <Anchor href="https://app.osmosis.zone/?from=ATOM&to=OSMO" target="_blank">
              Osmosis
            </Anchor>
            .
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={broadcastWithdrawStTokenError}
      />
    )
  }

  if (broadcastWithdrawStTokenError instanceof StandardTransactionError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description={broadcastWithdrawStTokenError.message}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
        error={broadcastWithdrawStTokenError}
      />
    )
  }

  if (broadcastWithdrawStTokenError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isBroadcastWithdrawStTokenSuccess) {
    return (
      <StepperModalContent
        title={`Success!`}
        description={`${formatMicroDenom(
          amount,
          chainInfo.stakeCurrency.coinMinimalDenom,
          3
        )} st${denom} has been withdrawn to your ${chainInfo.chainName} wallet.`}
        actions={<Button onClick={close}>Done</Button>}
      />
    )
  }

  return (
    <StepperModalContent
      title={`Transferring st${denom} to ${chainInfo.chainName}...`}
      description={`This could take 30 seconds or longer if the network is congested.`}
    />
  )
}

export { WithdrawStTokenStepTwo }
