import { TextLoader, useFormContext } from '@/components'
import { useSelectedCoin, useStrideBalancesV2, useStrideWallet } from '@/contexts/wallet'
import { Box, Divider, Group, Space, Text, TextInput, UnstyledButton } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import BigNumber from 'bignumber.js'
import { useSetAtom } from 'jotai'
import { useState } from 'react'
import { isUnstakingModalOpenAtom, unstakeAmountAtom } from '../atoms'
import { isHostZone } from '@/queries'
import { useHostZoneQuery } from '../queries'
import { UnstakeButton } from './UnstakeButton'
import { UnstakeEstimate } from './UnstakeEstimate'
import { UnstakeFormType } from './useUnstakeForm'
import { convertMicroDenomToDenom, formatMicroDenom } from '@/wallet-utils'
import { CHAIN_INFO_LIST } from '@/config'

const UnstakeForm: React.FC = () => {
  const strideAccount = useStrideWallet()

  const { data: strideBalances, isLoading: isStrideBalancesLoading } = useStrideBalancesV2()

  const stTokenBalance = strideBalances?.strideSelectedAccountStakedBalance ?? '0'

  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const setIsOpen = useSetAtom(isUnstakingModalOpenAtom)

  const setAmount = useSetAtom(unstakeAmountAtom)

  const [isFocused, setIsFocused] = useState(false)

  const { values, setFieldValue, getInputProps, onSubmit, validate } = useFormContext<UnstakeFormType>()

  const { data: hostZone } = useHostZoneQuery()

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleClickMax = () => {
    const stTokenBalanceInDenom = convertMicroDenomToDenom(stTokenBalance, chainInfo.stakeCurrency.coinMinimalDenom)
    const maxRoundedDown = new BigNumber(stTokenBalanceInDenom).decimalPlaces(5).toNumber()
    const max = Math.max(maxRoundedDown, 0)
    setFieldValue('amount', String(max))
  }

  const handleSubmit = () => {
    setAmount(Number(values.amount))
    setIsOpen(true)
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful unstake
    validate()
  }, [values, isFocused])

  const isUnstakedTextEnabled = () => {
    if (hostZone == null) return false
    return Number(isHostZone(hostZone) ? hostZone.total_delegations : hostZone.delegated_balance) === 0
  }

  const stakedDenom = `st${denom}`

  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Text align="center" sx={(t) => ({ color: t.colors.gray[9] })}>
          Unstake your {stakedDenom} tokens in exchange for {denom}.
          {isUnstakedTextEnabled() && (
            <span>&nbsp;Unstaking will be enabled after the first full delegation cycle, which takes 4 days.</span>
          )}
        </Text>

        <Space h="lg" />

        <Box>
          <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
            Amount to unstake:
          </Text>

          <Space h="xs" />

          <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
            {stakedDenom}
          </Text>
        </Box>

        <Space h="sm" />

        <TextInput
          required
          placeholder="0"
          size="xl"
          radius="md"
          sx={(t) => ({
            input: {
              fontWeight: 'bold',
              textAlign: 'right',
              color: t.colors.gray[9],
              '&::placeholder': { color: t.colors.gray[9] },
              '&:focus:not(.mantine-TextInput-invalid)': {
                border: `1px solid ${t.colors.gray[6]}`
              }
            }
          })}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...getInputProps('amount')}
        />

        {Boolean(strideAccount) && (
          <>
            <Space h="xs" />

            <Group position="apart" align="center">
              <Group spacing={0}>
                <Text sx={(t) => ({ color: t.colors.gray[7] })}>Available in wallet:</Text>
                &nbsp;
                <TextLoader loading={isStrideBalancesLoading} sx={(t) => ({ color: t.colors.gray[7] })}>
                  {formatMicroDenom(stTokenBalance, chainInfo.stakeCurrency.coinMinimalDenom, 5)} {stakedDenom}
                </TextLoader>
              </Group>

              <UnstyledButton onClick={handleClickMax}>
                <Text sx={(t) => ({ color: t.colors.pink[7] })}>Add max</Text>
              </UnstyledButton>
            </Group>
          </>
        )}

        <Space h="lg" />

        <Divider />

        <Space h="lg" />

        <Group position="apart" align="center">
          <Box>
            <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
              What you'll get:
            </Text>

            <Space h="xs" />

            <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
              {denom}
            </Text>
          </Box>

          <UnstakeEstimate />
        </Group>

        <Space h="lg" />

        <UnstakeButton />
      </form>
    </>
  )
}

export { UnstakeForm }
