import React, { useEffect } from 'react'
import { Button, Space, Skeleton, useMantineTheme, Anchor } from '@mantine/core'
import { StepperModalContent, useStepperContext } from '@/components'
import { assert } from '@/utils'
import { useAtomValue } from 'jotai'
import { useLiquidStakeTransactionAmountQuery } from './queries'
import { useWithdrawStTokenToDex } from './WithdrawStTokenToDexProvider'
import { useDexWallet, useSelectedCoin } from '@/contexts/wallet'
import { CHAIN_INFO_LIST, DEX_CONFIG, DEX_INFO_LIST } from '@/config'
import { useLatestValue } from '@/hooks'
import { notify } from '@/contexts/notifications'
import { withdrawStTokenToDexModalIsOpenAtom } from '../atoms'
import { StandardTransactionError } from '@/wallet-utils'

const WithdrawStTokenToDexStepOne: React.FC = () => {
  const {
    signStakeToWithdrawStTokenToDex,
    signStakeToWithdrawStTokenToDexError,
    broadcastStakeToWithdrawStTokenToDex,
    isBroadcastingStakeToWithdrawStTokenToDex,
    broadcastStakeToWithdrawStTokenToDexError,
    traceIbcStatus,
    isIbcStatusLoading
  } = useWithdrawStTokenToDex()

  const { start, nextStep, handleClose, forceClose } = useStepperContext()

  const denom = useSelectedCoin()

  const dexAccount = useDexWallet()

  const isOpen = useAtomValue(withdrawStTokenToDexModalIsOpenAtom)

  const isOpenRef = useLatestValue(isOpen)

  const {
    data: stakeTransactionAmount,
    isLoading: isStakeTransactionAmountLoading,
    error: stakeTransactionAmountError,
    refetch: refetchStakeTransaction
  } = useLiquidStakeTransactionAmountQuery()

  const theme = useMantineTheme()

  const handleBroadcast = async () => {
    const { status } = await broadcastStakeToWithdrawStTokenToDex()

    if (!isOpenRef.current) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler.
      return
    }

    if (status === 'return-later') {
      // If we're here, it means that our ibc transfer took too long and we're tracking it
      // the second time without the 30-second timeout. This way, the user can continue the
      // flow if it they haven't closed.
      //
      // We can check for the return-later status by checking using `isIbcStatusLoading`
      // to check if the ibc transfer hasn't completed and ibc tracking is still on-going.
      //
      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      // `status === 'timeout'` for both broadcast and tracing and `ibcStatusError`
      await traceIbcStatus()
    }

    nextStep()
  }

  const handleStart = async () => {
    start()
    await signStakeToWithdrawStTokenToDex()
    await handleBroadcast()
  }

  useEffect(() => {
    if (stakeTransactionAmount == null) return
    handleStart()
  }, [stakeTransactionAmount])

  const handleRetry = () => {
    handleStart()
  }

  const handleRefetch = () => {
    refetchStakeTransaction()
  }

  const handleCloseCallback = () => {
    // If it was closed before or after the broadcast, we don't need to show the toast.
    if (!isBroadcastingStakeToWithdrawStTokenToDex && !isIbcStatusLoading) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the transfer completes.")
  }

  handleClose(handleCloseCallback)

  if (dexAccount == null) {
    return null
  }

  const chainInfo = CHAIN_INFO_LIST[dexAccount.currency.coinDenom]

  const dexConfig = DEX_CONFIG[denom]

  assert(dexConfig, `Missing dex config for ${denom}`)

  const dexInfo = DEX_INFO_LIST[dexConfig.type]

  if (isIbcStatusLoading) {
    return (
      <StepperModalContent
        title="This transaction is taking longer than usual"
        description={`If you don’t want to wait here, you can close this dialog. Your transaction will continue and you’ll be able to see status on the Stride app page.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
      />
    )
  }

  if (
    broadcastStakeToWithdrawStTokenToDexError instanceof StandardTransactionError &&
    broadcastStakeToWithdrawStTokenToDexError.description === 'INSUFFICIENT_FUNDS'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            Insufficient funds for gas. Get STRD, ATOM or TIA on{' '}
            <Anchor href="https://app.osmosis.zone/?from=ATOM&to=OSMO" target="_blank">
              Osmosis
            </Anchor>
            .
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={broadcastStakeToWithdrawStTokenToDexError}
      />
    )
  }

  if (broadcastStakeToWithdrawStTokenToDexError instanceof StandardTransactionError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description={broadcastStakeToWithdrawStTokenToDexError.message}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
        error={broadcastStakeToWithdrawStTokenToDexError}
      />
    )
  }

  if (broadcastStakeToWithdrawStTokenToDexError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isBroadcastingStakeToWithdrawStTokenToDex) {
    return (
      <StepperModalContent
        title={`Transferring your st${denom} to ${chainInfo.chainName}...`}
        description={`This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue. Once the transfer is complete, you may use your st${denom} on ${dexInfo.name}.`}
      />
    )
  }

  if (stakeTransactionAmountError) {
    return (
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`Unable to proceed`}
        description="Failed to fetch liquid stake transaction."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRefetch}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isStakeTransactionAmountLoading) {
    return (
      <>
        <Skeleton width={120} height={theme.fontSizes.md} />
        <Space h="sm" />
        <Skeleton width={240} height={theme.fontSizes.sm} />
        <Space h="xs" />
        <Skeleton width={80} height={theme.fontSizes.sm} />
      </>
    )
  }

  if (signStakeToWithdrawStTokenToDexError) {
    return (
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  type RateLimitStatus = 'loose' | 'tight' | 'closed'

  // const [isRateLimitingWarningEnabled, setIsRateLimitingWarningEnabled] = useState(true)

  const status: RateLimitStatus = 'loose'

  if (status === 'loose') {
    return (
      <StepperModalContent
        title={`Approve the transaction in your  wallet to continue`}
        description={`This will start the transfer of your st${denom} tokens to ${chainInfo.chainName}.`}
      />
    )
  }

  return (
    <StepperModalContent
      // User must fund the entire transaction with IBC and has no tokens on Stride
      title={`Approve the transaction in your  wallet to continue`}
      description={`This will start the transfer of your st${denom} tokens to ${chainInfo.chainName}.`}
    />
  )
}

export { WithdrawStTokenToDexStepOne }
