import React, { useCallback } from 'react'
import { Button } from '@mantine/core'
import { StepperModalContent, useStepperContext } from '@/components'
import { useWithdrawModal } from './WithdrawModalProvider'
import { useSelectedCoin } from '@/contexts/wallet'
import { formatMicroDenom } from '@/wallet-utils'
import { CHAIN_INFO_LIST } from '@/config'

const WithdrawStepOne: React.FC = () => {
  const { start, nextStep, forceClose, close } = useStepperContext()

  const { withdrawAmount, signWithdraw, isSigningWithdraw, signWithdrawError } = useWithdrawModal()

  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const formattedAmount = `${formatMicroDenom(withdrawAmount, chainInfo.stakeCurrency.coinMinimalDenom, 3)} ${denom}`

  const handleStart = async () => {
    start()
    await signWithdraw(withdrawAmount)
    nextStep()
  }

  const handleStartWithdraw = useCallback(() => {
    handleStart()
  }, [])

  const handleRetrySignSendToken = useCallback(() => {
    handleStart()
  }, [])

  if (signWithdrawError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
            <Button color="dark" variant="outline" onClick={handleRetrySignSendToken}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningWithdraw) {
    return (
      <StepperModalContent
        title="Approve the transaction in your wallet to continue"
        description={`This will start the transfer of ${denom} tokens to your wallet.`}
      />
    )
  }

  return (
    <StepperModalContent
      title={`You're about to withdraw ${formattedAmount} from Stride`}
      description="This will move tokens from your balance on the Stride app to your wallet."
      actions={
        <>
          <Button variant="outline" color="dark" onClick={close}>
            Cancel
          </Button>

          <Button onClick={handleStartWithdraw}>Withdraw</Button>
        </>
      }
    />
  )
}

export { WithdrawStepOne }
