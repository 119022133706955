import { stride } from 'stridejs'
import { useAtomValue } from 'jotai'
import { useMutation } from '@tanstack/react-query'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'

import { CHAIN_INFO_LIST } from '@/config'
import { assert, fatal } from '@/utils'
import { convertDenomToMicroDenom, isSafeModeAccount } from '@/wallet-utils'
import { useSelectedCoin, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { stakeAmountAtom } from '@/page-components/Stake/atoms'
import { useReferral } from '@/page-components/Referral'
import { getLiquidStakeSafetyDeduction } from '../utils'
import { MutationParameters } from './types'

const useSignLiquidStakeMutation = ({ setLiquidStakeRaw }: MutationParameters) => {
  const { memo } = useReferral()

  const denom = useSelectedCoin()

  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const amount = useAtomValue(stakeAmountAtom)

  const handleMutation = async (): Promise<TxRaw> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (isSafeModeAccount(selectedAccount)) throw fatal('Safe mode is enabled.')

    assert(strideAccount.client, 'You are unable to stake without connecting to the stride rpc.')
    assert(!isSafeModeAccount(strideAccount), 'Safe mode is enabled.')

    const chainInfo = CHAIN_INFO_LIST[denom]

    const amountInMicroDenom = convertDenomToMicroDenom(amount, chainInfo.stakeCurrency.coinMinimalDenom)

    const deductible = getLiquidStakeSafetyDeduction({
      amount: amountInMicroDenom,
      denom,
      strideAccount
    })

    const amountWithSafetyDeduction = amountInMicroDenom - deductible

    const getMsgLiquidStake = () => {
      if (denom === 'TIA') {
        return stride.staketia.MessageComposer.withTypeUrl.liquidStake({
          staker: strideAccount.address,
          nativeAmount: amountWithSafetyDeduction.toString()
        })
      }

      if (denom === 'DYM') {
        return stride.stakedym.MessageComposer.withTypeUrl.liquidStake({
          staker: strideAccount.address,
          nativeAmount: amountWithSafetyDeduction.toString()
        })
      }

      return stride.stakeibc.MessageComposer.withTypeUrl.liquidStake({
        creator: strideAccount.address,
        amount: amountWithSafetyDeduction.toString(),
        hostDenom: chainInfo.stakeCurrency.coinMinimalDenom
      })
    }

    const msgLiquidStake = getMsgLiquidStake()

    // const gas = await strideAccount.client.simulate(strideAccount.address, [msgLiquidStake], '')

    // @Q: Can we use this and deduct its value to `getMsgLiquidStake`?
    // @TODO: This probably should be selectedAccount - we should prioritize using the host token as gas instead of STRD
    // const fee = calculateFee(selectedAccount, gas)

    // @TODO: figure out a way where the denom returned from calculate fee is TIA or ibc/1A7653323C1A9E267FF7BEBF40B3EEA8065E8F069F47F2493ABC3E0B621BF793
    const fee = {
      amount: [
        {
          denom: 'ibc/1A7653323C1A9E267FF7BEBF40B3EEA8065E8F069F47F2493ABC3E0B621BF793',
          amount: '10922'
        }
      ],
      gas: '109212'
    }

    return await strideAccount.client.sign(strideAccount.address, [msgLiquidStake], fee, memo)
  }

  const handleSuccess = (raw: TxRaw) => {
    setLiquidStakeRaw(raw)
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useSignLiquidStakeMutation }
